import { render, staticRenderFns } from "./rule.vue?vue&type=template&id=42d215eb&scoped=true"
var script = {}
import style0 from "./rule.vue?vue&type=style&index=0&id=42d215eb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42d215eb",
  null
  
)

export default component.exports